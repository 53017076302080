<template>
  <div class="push-container">
   推送管理
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang='scss'>
.push-container {
}
</style>